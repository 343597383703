import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { handleScroll } from "../helpers/helper-functions"
import { LanguageContext } from "../hooks/use-language"

const Pagination = (props) => {
  const { language } = useContext(LanguageContext)

  if (props.totalPages > 1)
    return (
      <div className="pagination">
        <Link
          to={`/${props.slug}/page/${props.currentPage - 1}?lang=${language}`}
          onClick={() => {
            props.handlePageChange(props.currentPage - 1)
            handleScroll("up")
          }}
          className={props.currentPage === 1 ? "is-not-visible" : "is-visible"}
          tabIndex={props.currentPage === 1 ? "-1" : "0"}
          aria-hidden={props.currentPage === 1 ? "true" : "false"}
          aria-label={language === "fi" ? "Edellinen sivu" : "Previous page"}
        >
          <ion-icon name="arrow-back-sharp"></ion-icon>
        </Link>
        <Link
          to={`/${props.slug}/page/${1}?lang=${language}`}
          onClick={() => {
            props.handlePageChange(1)
            handleScroll("up")
          }}
          className={props.currentPage < 6 ? "first is-not-visible" : "first is-visible"}
          tabIndex={props.currentPage < 6 ? "-1" : "0"}
          aria-hidden={props.currentPage < 6 ? "true" : "false"}
          aria-label={language === "fi" ? "Ensimmäinen sivu" : "First page"}
        >
          <ion-icon name="arrow-back-sharp" style={{ borderLeft: "3px solid black" }}></ion-icon>
        </Link>
        <div aria-hidden="true" className={props.currentPage < 6 ? "dots is-not-visible" : "dots is-visible"}>
          {"..."}
        </div>
        {props.pages.map((number, i) => (
          <Link
            to={`/${props.slug}/page/${number}?lang=${language}`}
            className={props.currentPage === number ? "pagination__page is-active" : "pagination__page"}
            onClick={() => {
              props.handlePageChange(number)
              handleScroll("up")
            }}
            key={i}
            aria-label={language === "fi" ? `Sivu ${number}` : `Page ${number}`}
          >
            {number}
          </Link>
        ))}
        <div
          aria-hidden="true"
          className={props.currentPage > props.totalPages - 5 ? "dots is-not-visible" : "dots is-visible"}
        >
          {"..."}
        </div>
        <Link
          to={`/${props.slug}/page/${props.totalPages}?lang=${language}`}
          onClick={() => {
            props.handlePageChange(props.totalPages)
            handleScroll("up")
          }}
          className={props.currentPage > props.totalPages - 5 ? "last is-not-visible" : "last is-visible"}
          tabIndex={props.currentPage > props.totalPages - 5 ? "-1" : "0"}
          aria-hidden={props.currentPage > props.totalPages - 5 ? "true" : "false"}
          aria-label={language === "fi" ? "Viimeinen sivu" : "Last page"}
        >
          <ion-icon name="arrow-forward-sharp" style={{ borderRight: "3px solid black" }}></ion-icon>
        </Link>
        <Link
          to={`/${props.slug}/page/${props.currentPage + 1}?lang=${language}`}
          onClick={() => {
            props.handlePageChange(props.currentPage + 1)
            handleScroll("up")
          }}
          className={props.currentPage === props.totalPages ? "is-not-visible" : "is-visible"}
          tabIndex={props.currentPage === props.totalPages ? "-1" : "0"}
          aria-hidden={props.currentPage === props.totalPages ? "true" : "false"}
          aria-label={language === "fi" ? "Seuraava sivu" : "Next page"}
        >
          <ion-icon name="arrow-forward-sharp"></ion-icon>
        </Link>
      </div>
    )
  else return null
}

export default Pagination
