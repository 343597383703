import React, { useContext } from "react"
import Typewriter from "./Typewriter"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { LanguageContext } from "../hooks/use-language"

const NotFound = () => {
  const { language } = useContext(LanguageContext)

  return (
    <>
      <Helmet>
        <title>{`${language === "fi" ? "Virhe" : "Error"} - Finnish Design Info`}</title>
      </Helmet>
      <div className="error">
        <div className="error__container">
          <div className="error__icon">
            <Typewriter key={language} text="404" />
          </div>
          <div className="error__content">
            <Typewriter key={language} size="h1" text={language === "fi" ? "Nyt tapahtui virhe" : "That's an error"} />
            <p>
              {language === "fi"
                ? "Emme löytäneet etsimääsi sivua."
                : "We can't seem to find the page you’re looking for."}
            </p>
            <div className="error__buttons">
              <Link to={`/?lang=${language}`} className="button">
                {language === "fi" ? "Mene etusivulle" : "Go home"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
