import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Date as PrismicDate, RichText } from "prismic-reactjs"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import ProgressiveImage from "react-progressive-image-loading"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

import client from "../prismic-configuration"
import Related from "./Related"
import customLink from "../customLink"
import Header from "./Header"
import Person from "./Person"
import { ReactComponent as FacebookShareIcon } from "../icons/facebook-share-icon.svg"
import { ReactComponent as LinkedinShareIcon } from "../icons/linkedin-share-icon.svg"
import { ReactComponent as TwitterShareIcon } from "../icons/twitter-share-icon.svg"
import { ReactComponent as WhatsappShareIcon } from "../icons/whatsapp-share-icon.svg"
import { ReactComponent as EmailShareIcon } from "../icons/email-share-icon.svg"
import NotFound from "./NotFound"
import { LanguageContext } from "../hooks/use-language"
import { useCurrentPage } from "../hooks/use-current-page"

const SingleNews = (props) => {
  const { match } = props
  useCurrentPage(match.url)
  const [doc, setDoc] = useState({})
  const [author, setAuthor] = useState({})
  const [notFound, setNotFound] = useState(false)
  const { language, setAltLangSlug } = useContext(LanguageContext)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await client.getByUID("news", match.params.slug, { lang: language.toLowerCase() })

        if (res) {
          const timestamp = PrismicDate(res?.first_publication_date)
          const formattedTimestamp = Intl.DateTimeFormat("fi", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }).format(timestamp)

          setDoc({
            title: res.data.title[0]?.text,
            date: formattedTimestamp,
            uid: res.uid,
            tags: res.tags,
            meta_description: res.data.meta_description[0]?.text,
            hero_image: res.data.hero_image,
            hero_image_caption: res.data.hero_image_caption,
            slices: res.data.body,
            alternate_languages: res.alternate_languages,
            lang: language,
          })

          if (res.alternate_languages.length > 0 && match.params.slug !== res.alternate_languages[0].uid) {
            setAltLangSlug({ pathname: res.alternate_languages[0].uid, language: res.alternate_languages[0].lang })
          }

          if (res.data.author.uid) {
            const authres = await client.getByUID("people", res.data.author.uid, { lang: language })
            setAuthor(authres)
          } else {
            setAuthor({})
          }
        } else {
          setNotFound(true)
        }
      } catch (error) {
        console.log("err", error)
        setNotFound(true)
      }
    })()

    return () => {
      setAltLangSlug(null)
      setNotFound(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, match.params.slug])

  const docTags = doc?.tags?.map((tag, i) => {
    return (
      <li className="tag" key={i}>
        <Link to={`/stories-tag/${tag}/page/1?lang=${language}`}>{tag}</Link>
      </li>
    )
  })

  // Slices to render
  const docSlices = doc?.slices?.map((slice, i) => {
    // Title
    if (slice.slice_type === "title") {
      return <RichText key={i} render={slice.primary.content} serializeHyperlink={customLink} />

      // Paragraph
    } else if (slice.slice_type === "paragraph") {
      return <RichText key={i} render={slice.primary.content} serializeHyperlink={customLink} />

      // Image with caption
    } else if (slice.slice_type === "image_with_caption") {
      return (
        <div key={i} className="image-with-caption">
          <ProgressiveImage
            preview={slice.primary.image.preview.url}
            src={slice.primary.image.url}
            render={(src, style) => <img src={src} style={style} alt={slice.primary.image.alt} />}
          />
          {slice.primary.caption && slice.primary.caption.length > 0 && (
            <p className="caption">{slice.primary.caption[0].text}</p>
          )}
        </div>
      )

      // Button
    } else if (slice.slice_type === "button") {
      // Web or Media link
      if (slice.primary.link.link_type === "Web" || slice.primary.link.link_type === "Media") {
        return (
          slice.primary.link_text[0] &&
          slice.primary.link.url && (
            <div key={i} className="button-container">
              <a
                className={`button ${slice.primary.button_style ? slice.primary.button_style : ""}`}
                href={slice.primary.link.url}
                target="_blank"
                rel="noreferrer"
              >
                {slice.primary.link_text[0].text}
              </a>
            </div>
          )
        )
      }
      // Link to case document
      else if (slice.primary.link.type === "case") {
        return (
          slice.primary.link.uid &&
          slice.primary.link_text[0] && (
            <div key={i} className="button-container">
              <Link className="button" to={`/cases/${slice.primary.link.uid}`}>
                {slice.primary.link_text[0].text}
              </Link>
            </div>
          )
        )
      }
      // Link to news document
      else if (slice.primary.link.type === "news") {
        return (
          slice.primary.link_text[0] &&
          slice.primary.link.uid && (
            <div key={i} className="button-container">
              <Link className="button" to={`/news/${slice.primary.link.uid}`}>
                {slice.primary.link_text[0].text}
              </Link>
            </div>
          )
        )
      }

      // Link to page or else
      else {
        return (
          slice.primary.link_text[0] &&
          slice.primary.link.uid && (
            <div key={i} className="button-container">
              <Link className="button" to={`/${slice.primary.link.uid}`}>
                {slice.primary.link_text[0].text}
              </Link>
            </div>
          )
        )
      }

      // Embed
    } else if (slice.slice_type === "embed") {
      const iframe = slice.primary.embed_url[0]
        ? `<iframe src="${slice.primary.embed_url[0].text}" title="embedFrame" />`
        : ""
      return iframe !== "" && <div key={i} className="embed-container" dangerouslySetInnerHTML={{ __html: iframe }} />
      // Quote
    } else if (slice.slice_type === "quote") {
      return (
        <div className="quote" key={i}>
          {slice.primary.content && <p>{RichText.asText(slice.primary.content)}</p>}
          {slice.primary.quotee && <p className="quotee">{RichText.asText(slice.primary.quotee)}</p>}
        </div>
      )
      // Lead
    } else if (slice.slice_type === "lead") {
      return (
        <div className="lead" key={i}>
          <RichText render={slice.primary.content} serializeHyperlink={customLink} />
        </div>
      )
      // Return null by default
    } else {
      return null
    }
  })

  return notFound ? (
    <NotFound />
  ) : (
    <>
      <Helmet>
        <title>{doc.title ? `${doc.title} - Finnish Design Info` : ""}</title>
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>
      <TransitionGroup component={null}>
        {doc?.slices?.length > 0 && (
          <CSSTransition key={doc.uid} timeout={500} classNames="fade">
            <div className="page">
              <Header text={doc.title} date={doc.date} tags={docTags} />
              <div className="document__body">
                <div className="document__image-wrap">
                  {doc.hero_image.url && (
                    <ProgressiveImage
                      preview={doc.hero_image?.preview?.url}
                      src={doc.hero_image?.url}
                      render={(src, style) => (
                        <img
                          className="document__image document__image--article"
                          src={src}
                          style={style}
                          alt={doc.hero_image.alt}
                        />
                      )}
                    />
                  )}
                  {doc.hero_image_caption && doc.hero_image.url && (
                    <div className="document__image-caption">
                      <RichText render={doc.hero_image_caption} serializeHyperlink={customLink} />
                    </div>
                  )}
                </div>
                <div className="document__content">
                  <main className="document__slices">{docSlices}</main>
                  <aside className="document__meta">
                    <div className="meta-wrap">
                      <p className="updated">{doc.date}</p>
                      <ul className="tags">{docTags}</ul>
                    </div>
                    {author.uid && (
                      <>
                        <p className="author">{language === "fi" ? "Kirjoittaja" : "Author"}</p>
                        <Person person={author} />
                      </>
                    )}
                    <p>{language === "fi" ? "Jaa artikkeli" : "Share article"}</p>
                    <div className="share-buttons">
                      <FacebookShareButton url={window.location.href}>
                        <FacebookShareIcon />
                      </FacebookShareButton>
                      <LinkedinShareButton url={window.location.href}>
                        <LinkedinShareIcon />
                      </LinkedinShareButton>
                      <TwitterShareButton url={window.location.href}>
                        <TwitterShareIcon />
                      </TwitterShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <WhatsappShareIcon />
                      </WhatsappShareButton>
                      <EmailShareButton url={window.location.href}>
                        <EmailShareIcon />
                      </EmailShareButton>
                    </div>
                  </aside>
                </div>
              </div>
              {doc.tags && doc.uid ? <Related type="news" tags={doc.tags} excludeUID={doc.uid} /> : ""}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

export default SingleNews
