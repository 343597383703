import React, { createContext, useState, useEffect, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("fi") // Default language is Finnish
  const [altLangSlug, setAltLangSlug] = useState(null)
  const history = useHistory()
  const location = useLocation()

  // Declare switchLanguage as useCallback to prevent re-creation on each render
  const switchLanguage = useCallback(
    (language) => {
      setLanguage(language)
      const params = new URLSearchParams(location.search)
      params.set("lang", language)
      let replace = { search: params.toString() }

      if (altLangSlug && altLangSlug.pathname && altLangSlug.language === language.toLowerCase()) {
        replace.pathname = altLangSlug.pathname
      }

      history.replace(replace)
    },
    [location.search, history, altLangSlug]
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const lang = params.get("lang")
    if (lang && lang !== language) {
      switchLanguage(lang)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, language, switchLanguage])

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, setAltLangSlug }}>{children}</LanguageContext.Provider>
  )
}
